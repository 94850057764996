import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Un @mixin Sass simple pour les media queries",
  "description": "Comment facilement gérer ses media queries avec Sass et un @mixin.",
  "date": "2013-05-27T00:00:00.000Z",
  "path": "/articles/un-mixin-sass-simple-pour-les-media-queries/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`J’avais `}<a parentName="p" {...{
        "href": "/articles/le-jour-ou-jai-teste-sass/"
      }}>{`chanté les louanges`}</a>{` du préprocesseur Sass il y a quelques mois, convaincu de l’intérêt de cet outil. Depuis je l’ai intégré en production sur quelques projets, et son intérêt ne se dément vraiment pas. Combiné à les logiciels simples (je vous conseille vivement `}<a parentName="p" {...{
        "href": "http://alphapixels.com/prepros/"
      }}>{`Prepros`}</a>{` sur Windows) c’est un bonheur à utiliser.`}</p>
    <p>{`Les `}<code parentName="p" {...{
        "className": "text"
      }}>{`@mixins`}</code>{` sont un vrai plus de Sass. Pour rappel ils permettent de réutiliser grâce à `}<code parentName="p" {...{
        "className": "text"
      }}>{`@include`}</code>{` une partie de notre CSS, qu’on peut par exemple combiner avec des variables. J’ai pu en récupérer quelques-uns assez utiles à droite et à gauche, que je partagerai prochainement ici.`}</p>
    <p>{`Je suis notamment tombé sur un `}<code parentName="p" {...{
        "className": "text"
      }}>{`@mixin`}</code>{` assez intéressant pour gérer les media queries, publié dans un article de `}<a parentName="p" {...{
        "href": "http://web-design-weekly.com/2013/05/12/handy-sass-mixins/"
      }}>{`Web Design Weekly`}</a>{`. Le `}<code parentName="p" {...{
        "className": "text"
      }}>{`@mixin`}</code>{` utilisé est le suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "scss"
      }}><code parentName="pre" {...{
          "className": "scss"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`@mixin`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`breakpoint`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$point`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@if`}</span>{` `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$point`}</span>{` == large `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 64.375em`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@else if`}</span>{` `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$point`}</span>{` == medium `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 50em`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@else if`}</span>{` `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$point`}</span>{` == small `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 37.5em`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Il permet donc d’ajouter facilement des breakpoints à un design responsive. Ainsi pour reprendre l’exemple de l’article, le SCSS suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "scss"
      }}><code parentName="pre" {...{
          "className": "scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.page-wrap `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 75%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@include`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`breakpoint`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`large`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 60%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Se transformera en :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.page-wrap`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 75%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 64.375em`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.page-wrap`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 60%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`C’est un `}<code parentName="p" {...{
        "className": "text"
      }}>{`@mixin`}</code>{` très utile mais je voulais le simplifier et le rendre un peu plus flexible, histoire de l’adapter sur mesure à différents media queries. Je l’ai donc transformé en ceci :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "scss"
      }}><code parentName="pre" {...{
          "className": "scss"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`@mixin`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`bp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$point`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` screen `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`and`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$point`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{` 16 `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`+`}</span>{` em`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`@content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Il suffit ensuite simplement de remplacer la variable `}<code parentName="p" {...{
        "className": "text"
      }}>{`$point`}</code>{` par la taille que l’on souhaite pour notre media queries (query ?) en pixels, et celle-ci sera automatiquement convertie en `}<code parentName="p" {...{
        "className": "text"
      }}>{`em`}</code>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      